<template>
  <v-container class="px-0">
    <SummaryHeader />
    <SummaryMetrics />
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <v-row>
          <v-col cols="12" md="6" sm="12">
            <PieChartArea />
          </v-col>
          <v-col cols="12" md="6" sm="12">
            <BaseAndImpact />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" sm="12">
            <SalesTrendModelFit />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Summary",
  components: {
    SummaryHeader: () => import("@/views/results/summary/SummaryHeader"),
    SummaryMetrics: () => import("@/views/results/summary/SummaryMetrics"),
    PieChartArea: () => import("@/views/results/summary/PieChartArea"),
    BaseAndImpact: () => import("@/views/results/summary/BaseAndImpact"),
    SalesTrendModelFit: () =>
      import("@/views/results/summary/SalesTrendModelFit"),
  },
};
</script>

<style scoped></style>
